import React from 'react';
import Header from '../Common/Header';
import Footer from '../Common/Footer';

const projects = [
  {
    id: 'clinica-de-estetica-da-mulher',
    title: "Clínica de Estética da Mulher",
    images: [
      require('./../../images/projects/clinica_estetica/01.jpg'),
      require('./../../images/projects/clinica_estetica/02.jpg'),
      require('./../../images/projects/clinica_estetica/03.jpg'),
      require('./../../images/projects/clinica_estetica/04.jpg'),
      require('./../../images/projects/clinica_estetica/05.jpg'),
      require('./../../images/projects/clinica_estetica/06.jpg'),
      require('./../../images/projects/clinica_estetica/09.jpg'),
      require('./../../images/projects/clinica_estetica/10.jpg'),
      require('./../../images/projects/clinica_estetica/11.jpg'),
      require('./../../images/projects/clinica_estetica/12.jpg'),
      require('./../../images/projects/clinica_estetica/13.jpg'),
      require('./../../images/projects/clinica_estetica/14.jpg'),
      require('./../../images/projects/clinica_estetica/15.jpg'),
    ]
  },
  {
    id: 'mundo-de-alice',
    title: "Mundo de Alice",
    images: [
      require('./../../images/projects/mundo_de_alice/01.jpg'),
      require('./../../images/projects/mundo_de_alice/02.jpg'),
      require('./../../images/projects/mundo_de_alice/03.jpg'),
      require('./../../images/projects/mundo_de_alice/04.jpg'),
      require('./../../images/projects/mundo_de_alice/05.jpg'),
      require('./../../images/projects/mundo_de_alice/06.jpg'),
      require('./../../images/projects/mundo_de_alice/07.jpg'),
    ]
  },
  {
    id: 'apartamento',
    title: "Apartamento",
    images: [
      require('./../../images/projects/apartamento/01.jpg'),
      require('./../../images/projects/apartamento/02.jpg'),
      require('./../../images/projects/apartamento/03.jpg'),
      require('./../../images/projects/apartamento/04.jpg'),
      require('./../../images/projects/apartamento/05.jpg'),
      require('./../../images/projects/apartamento/06.jpg'),
      require('./../../images/projects/apartamento/07.jpg'),
      require('./../../images/projects/apartamento/08.jpg'),
      require('./../../images/projects/apartamento/09.jpg'),
    ]
  },
  {
    id: 'area-externa',
    title: "Área Externa",
    images: [
      require('./../../images/projects/area_externa/01.jpg'),
      require('./../../images/projects/area_externa/02.jpg'),
      require('./../../images/projects/area_externa/03.jpg'),
      require('./../../images/projects/area_externa/04.jpg'),
    ]
  },
  {
    id: 'casa-na-praia',
    title: "Casa na Praia",
    images: [
      require('./../../images/projects/casa_na_praia/01.jpg'),
      require('./../../images/projects/casa_na_praia/02.jpg'),
      require('./../../images/projects/casa_na_praia/03.jpg'),
      require('./../../images/projects/casa_na_praia/04.jpg'),
      require('./../../images/projects/casa_na_praia/05.jpg'),
      require('./../../images/projects/casa_na_praia/06.jpg'),
      require('./../../images/projects/casa_na_praia/07.jpg'),
      require('./../../images/projects/casa_na_praia/08.jpg'),
      require('./../../images/projects/casa_na_praia/09.jpg'),
      require('./../../images/projects/casa_na_praia/10.jpg'),
      require('./../../images/projects/casa_na_praia/11.jpg'),
      require('./../../images/projects/casa_na_praia/12.jpg'),
    ]
  },
  {
    id: 'clinica-odontologica',
    title: "Clínica Odontologica",
    images: [
      require('./../../images/projects/clinica_odontologica/01.jpg'),
      require('./../../images/projects/clinica_odontologica/02.jpg'),
      require('./../../images/projects/clinica_odontologica/03.jpg'),
      require('./../../images/projects/clinica_odontologica/04.jpg'),
      require('./../../images/projects/clinica_odontologica/05.jpg'),
      require('./../../images/projects/clinica_odontologica/06.jpg'),
      require('./../../images/projects/clinica_odontologica/07.jpg'),
      require('./../../images/projects/clinica_odontologica/08.jpg'),
    ]
  },
  {
    id: 'cozinha-open-space',
    title: "Cozinha Open Space",
    images: [
      require('./../../images/projects/cozinha_open_space/01.jpg'),
      require('./../../images/projects/cozinha_open_space/02.jpg'),
      require('./../../images/projects/cozinha_open_space/03.jpg'),
      require('./../../images/projects/cozinha_open_space/04.jpg'),
      require('./../../images/projects/cozinha_open_space/05.jpg'),
      require('./../../images/projects/cozinha_open_space/06.jpg'),
    ]
  },
  {
    id: 'homeoffice-psicologa',
    title: "Homeoffice Psicóloga",
    images: [
      require('./../../images/projects/homeoffice_psicologa/01.jpg'),
      require('./../../images/projects/homeoffice_psicologa/02.jpg'),
      require('./../../images/projects/homeoffice_psicologa/03.jpg'),
      require('./../../images/projects/homeoffice_psicologa/04.jpg'),
      require('./../../images/projects/homeoffice_psicologa/05.jpg'),
      require('./../../images/projects/homeoffice_psicologa/06.jpg'),
      require('./../../images/projects/homeoffice_psicologa/07.jpg'),
    ]
  },
  {
    id: 'quarto-adolescente',
    title: "Quarto Adolescente",
    images: [
      require('./../../images/projects/quarto_adolescente/01.jpg'),
      require('./../../images/projects/quarto_adolescente/02.jpg'),
      require('./../../images/projects/quarto_adolescente/03.jpg'),
      require('./../../images/projects/quarto_adolescente/04.jpg'),
      require('./../../images/projects/quarto_adolescente/05.jpg'),
      require('./../../images/projects/quarto_adolescente/06.jpg'),
      require('./../../images/projects/quarto_adolescente/07.jpg'),
    ]
  },
  {
    id: 'quarto-hospedes',
    title: "Quarto Hóspedes",
    images: [
      require('./../../images/projects/quarto_hospedes/01.jpg'),
      require('./../../images/projects/quarto_hospedes/02.jpg'),
      require('./../../images/projects/quarto_hospedes/03.jpg'),
      require('./../../images/projects/quarto_hospedes/04.jpg'),
      require('./../../images/projects/quarto_hospedes/05.jpg'),
      require('./../../images/projects/quarto_hospedes/06.jpg'),
    ]
  },
  {
    id: 'quarto-menina',
    title: "Quarto Menina",
    images: [
      require('./../../images/projects/quarto_menina/01.jpg'),
      require('./../../images/projects/quarto_menina/02.jpg'),
      require('./../../images/projects/quarto_menina/03.jpg'),
      require('./../../images/projects/quarto_menina/04.jpg'),
      require('./../../images/projects/quarto_menina/05.jpg'),
    ]
  },
  {
    id: 'wc',
    title: "Casa de Banho",
    images: [
      require('./../../images/projects/wc/01.jpg'),
      require('./../../images/projects/wc/02.jpg'),
      require('./../../images/projects/wc/03.jpg'),
      require('./../../images/projects/wc/04.jpg'),
      require('./../../images/projects/wc/05.jpg'),
      require('./../../images/projects/wc/06.jpg'),
      require('./../../images/projects/wc/07.jpg'),
    ]
  }
];

class Projects extends React.Component {
  componentDidMount() {
    function loadScript(src) {

      return new Promise(function (resolve, reject) {
        var script = document.createElement('script');
        script.src = src;
        script.addEventListener('load', function () {
          resolve();
        });
        script.addEventListener('error', function (e) {
          reject(e);
        });
        document.body.appendChild(script);
        document.body.removeChild(script);
      })
    };

    loadScript('./assets/js/custom.js');
  };

  disableContextMenu(e) {
    // prevent the right-click menu from appearing
    e.preventDefault()
  }

  render() {
    const { id } = this.props.match.params;

    if (id === undefined) {
      return <></>
    }

    return (
      <>
        <Header />
        <div className="page-content">
          {/* SECTION CONTENT START */}
          <div className="section-full p-tb90 tm-news-wrap">
            <div className='container'>
              <h4 style={{ color: '#000', marginTop: "20px", marginLeft: "20px" }}>
                {projects.filter(project => project.id === id)[0].title}
              </h4>
            </div>
            {/* GALLERY CONTENT START */}
            <div className="container-fluid">
              <div className="portfolio-wrap mfp-gallery news-masonry clearfix">
                <div className="row">
                  {projects.filter(project => project.id === id)[0].images.map((item, index) => (
                    <div key={index} className="container">
                      <div className='col-xs-12'>
                        <div className="blog-post date-style-1">
                          <img className='image-fluid' src={item.default} alt="" onContextMenu={(e) => this.disableContextMenu(e)} onDragStart={this.disableContextMenu} />
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </>
    );
  };
};

export default Projects;