import React from "react";
import { NavLink } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { FormattedMessage } from "react-intl";

const filters = [
  { label: <FormattedMessage id="page_latestProjects_filter_interiores" />, filter: ".interior" },
  { label: <FormattedMessage id="page_latestProjects_filter_area_externa" />, filter: ".area-externa" },
  { label: <FormattedMessage id="page_latestProjects_filter_corporativo" />, filter: ".corporativo" },
];

const projects = [
  {
    image: require("./../../images/gallery/portrait/01.webp"),
    title: "WC",
    description: "",
    filter: "interior",
    href: "/projects/wc"
  },
  {
    image: require("./../../images/gallery/portrait/02.webp"),
    title: "Quarto Adolescente",
    description: "",
    filter: "interior",
    href: "/projects/quarto-adolescente"
  },
  {
    image: require("./../../images/gallery/portrait/03.webp"),
    title: "Cozinha Open Space",
    description: "",
    filter: "interior",
    href: "/projects/cozinha-open-space"
  },
  {
    image: require("./../../images/gallery/portrait/04.webp"),
    title: "Área Externa",
    description: "",
    filter: "area-externa",
    href: "/projects/area-externa"
  },
  {
    image: require("./../../images/gallery/portrait/05.webp"),
    title: "Quarto Menina",
    description: "",
    filter: "interior",
    href: "/projects/quarto-menina"
  },
  {
    image: require("./../../images/gallery/portrait/06.webp"),
    title: "Apartamento",
    description: "",
    filter: "interior",
    href: "/projects/apartamento"
  },
  {
    image: require("./../../images/gallery/portrait/08.webp"),
    title: "Mundo de Alice",
    description: "",
    filter: "interior",
    href: "/projects/mundo-de-alice"
  },
  {
    image: require("./../../images/gallery/portrait/09.webp"),
    title: "Home office Psicóloga",
    description: "",
    filter: "interior",
    href: "/projects/homeoffice-psicologa"
  },
  {
    image: require("./../../images/gallery/portrait/10.webp"),
    title: "Clínica de Estética da Mulher",
    description: "",
    filter: "interior",
    href: "/projects/clinica-de-estetica-da-mulher"
  },
  {
    image: require("./../../images/gallery/portrait/11.webp"),
    title: "Casa na Praia",
    description: "",
    filter: "interior",
    href: "/projects/casa-na-praia"
  },
  {
    image: require("./../../images/gallery/portrait/12.webp"),
    title: "Quarto Hóspedes",
    description: "",
    filter: "interior",
    href: "/projects/quarto-hospedes"
  },
  {
    image: require("./../../images/gallery/portrait/13.jpg"),
    title: "Clínica Odontológica",
    description: "",
    filter: "interior",
    href: "/projects/clinica-odontologica"
  },
];

class LatestProjects extends React.Component {
  componentDidMount() {
    function loadScript(src) {
      return new Promise(function (resolve, reject) {
        var script = document.createElement("script");
        script.src = src;
        script.addEventListener("load", function () {
          resolve();
        });
        script.addEventListener("error", function (e) {
          reject(e);
        });
        document.body.appendChild(script);
        document.body.removeChild(script);
      });
    }

    loadScript("./assets/js/custom.js");
  }

  render() {
    const { t } = this.props;
    const options = {
      loop: false,
      autoplay: true,
      margin: 20,
      nav: true,
      dots: false,
      navText: ['<i class="fa fa-angle-left"></i>', '<i class="fa fa-angle-right"></i>'],
      responsive: {
        0: {
          items: 1,
        },
        480: {
          items: 1,
        },
        580: {
          items: 2,
        },
        767: {
          items: 2,
        },
        991: {
          items: 3,
        },
        1152: {
          items: 4,
        },
        1360: {
          items: 4,
        },
        1366: {
          items: 5,
        },
      },
    };
    return (
      <div className="section-full p-t90 p-lr80 latest_project-outer square_shape3">
        {/* TITLE START */}
        <div className="section-head text-left">
          <div className="row">
            <div className="col-lg-4 col-md-12 ">
              <h2 className="text-uppercase font-36 text-blue-primary">
                <FormattedMessage id="page_latestProjects_title" />
              </h2>
              <div className="wt-separator-outer">
                <div className="wt-separator bg-blue-primary" />
              </div>
            </div>
            <div className="col-lg-8 col-md-12">
              <ul className="btn-filter-wrap">
                <li className="btn-filter btn-active" data-filter="*">
                  <FormattedMessage id="page_latestProjects_filter_todos_projetos" />
                </li>
                {filters.map((item, index) => (
                  <li key={index} className="btn-filter" data-filter={item.filter}>
                    {item.label}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
        {/* TITLE END */}
        {/* IMAGE CAROUSEL START */}
        <div className="section-content">
          <OwlCarousel className="owl-carousel owl-carousel-filter  owl-btn-bottom-left" {...options}>
            {projects.map((item, index) => (
              <div key={index} className={`${item.filter} item fadingcol`}>
                <div className="wt-img-effect ">
                  <img src={item.image.default} alt="" />
                  <div className="overlay-bx-2 ">
                    <div className="line-amiation">
                      <div className="text-white  font-weight-300 p-a40">
                        <h2>
                          <NavLink to={item.href} className="text-white font-20 letter-spacing-1 text-uppercase">
                            {item.title}
                          </NavLink>
                        </h2>
                        <p>{item.description}</p>
                        <NavLink to={item.href} className="v-button letter-spacing-4 font-12 text-uppercase p-l20">
                          Read More
                        </NavLink>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </OwlCarousel>
        </div>
        <div className="hilite-title p-lr20 m-tb20 text-right text-uppercase bdr-gray bdr-right">
          <strong><FormattedMessage id="page_latestProjects_filter_designs" /></strong>
          <span className="text-blue-primary"><FormattedMessage id="page_latestProjects_filter_incriveis" /></span>
        </div>
      </div>
    );
  }
}

export default LatestProjects;