import { LOCALES } from "./locales";

export const messages = {
    [LOCALES.ENGLISH]: {
        portugues: "Portuguese",
        ingles: "English",
        page_latestProjects_title: "3D Studio",
        page_latestProjects_filter_todos_projetos: "All Project",
        page_latestProjects_filter_interiores: "Interior",
        page_latestProjects_filter_area_externa: "External",
        page_latestProjects_filter_corporativo: "Corp",
        
        page_teammembers_fundadora: "Founder",
        page_teammembers_design_de_produto: "Product Design",
        page_teammembers_renderizacao_de_imagens: "Imagem Rendering",
        page_teammembers_concepcao_de_projeto: "Project Conception",
        page_teammembers_modelagem_3d: "3D Modeling",
        page_teammembers_arquiteta_e_designer_de_interiores: "Architect and Interior Designer",
        page_latestProjects_filter_designs: "Incredible",
        page_latestProjects_filter_incriveis: "Designs",

        page_timeline_informacoes_cliente_orcamento: "1. Customer Information and Quote",
        page_timeline_informacoes_cliente_orcamento_descricao: "This step involves the initial contact to gather all necessary information for the project's progress and successful completion, such as: Development, process, deadlines, and contract.",
        page_timeline_escopo_modelagem_3d: "2. Scope and 3D Modeling",
        page_timeline_escopo_modelagem_3d_descricao: "Development stage based on previously sent references.",
        page_timeline_imagens_previas_para_feedback: "3. Preview Images for Feedback",
        page_timeline_imagens_previas_para_feedback_descricao: "Sending low-resolution images for approval and revisions if necessary",
        page_timeline_apresentacao_proposta: "4. Proposal Presentation",
        page_timeline_apresentacao_proposta_descricao: "Proposal presentation within the stipulated deadline for approval",
        page_timeline_entrega_final: "5. Final Delivery",
        page_timeline_entrega_final_descricao: "Rendering and Post-production",
    },
    [LOCALES.PORTUGUESE]: {
        portugues: "Português",
        ingles: "Inglês",
        page_latestProjects_title: "Studio 3D",
        page_latestProjects_filter_todos_projetos: "Todas as Categorias",
        page_latestProjects_filter_interiores: "Interiores",
        page_latestProjects_filter_area_externa: "Área Externa",
        page_latestProjects_filter_corporativo: "Corporativo",

        page_teammembers_fundadora: "Fundadora",
        page_teammembers_design_de_produto: "Design de produto",
        page_teammembers_renderizacao_de_imagens: "Renderização de Imagens",
        page_teammembers_concepcao_de_projeto: "Concepção de Projeto",
        page_teammembers_modelagem_3d: "Modelagem 3D",
        page_teammembers_arquiteta_e_designer_de_interiores: "Arquiteta e Designer de Interiores",
        page_latestProjects_filter_designs: "Designs",
        page_latestProjects_filter_incriveis: "Incríveis",

        page_timeline_informacoes_cliente_orcamento: "1. Informações do Cliente e Orçamento",
        page_timeline_informacoes_cliente_orcamento_descricao: "Esta etapa consiste no primeiro contacto a obter todas as informações necessárias ao seguimento do trabalho e sua boa elaboração, tais como: Desenvolvimento, processo, prazos e contrato.",
        page_timeline_escopo_modelagem_3d: "2. Escopo e Modelagem 3D",
        page_timeline_escopo_modelagem_3d_descricao: "Etapa de desenvolvimento com base nas referências enviadas previamente.",
        page_timeline_imagens_previas_para_feedback: "3. Imagens Prévias para Feedback",
        page_timeline_imagens_previas_para_feedback_descricao: "Envio das imagens em baixa resolução para aprovação e retificações se necessário",
        page_timeline_apresentacao_proposta: "4. Apresentação proposta",
        page_timeline_apresentacao_proposta_descricao: "Apresentação de proposta dentro do prazo estipulado para aprovação",
        page_timeline_entrega_final: "5. Entrega Final",
        page_timeline_entrega_final_descricao: "Renderização e pós produção",
    }
};